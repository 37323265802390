import BlogPage from "../components/pages/blog";
import Language from "../translations/translator";

const Blog = (props) => (
  <Language language="pt">
    <BlogPage {...props} />
  </Language>
);

export default Blog;
